import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { Router } from "@angular/router";
import { environment } from "../../environments/environment";
import { NotificationService } from './notification.service';

const BACKEND_URL = environment.apiUrl;

export interface Agents {
  _id: string;
  name: string;
  phone: number;
  email: string;
  token: string;
  resetToken: string,
  resetTokenDate: Date
}

@Injectable()
export class AuthService {
  private isAuthenticated = false;
  private token: string;
  private user: any;
  private tokenTimer: any;
  private role: string;
  private authStatusListener = new Subject<boolean>();

  constructor(private http: HttpClient, private router: Router, private notificationService: NotificationService) {
  }

  getToken() {
    return this.token;
  }

  getIsAuth() {
    return this.isAuthenticated;
  }

  getUser() {
    this.user = JSON.parse(localStorage.getItem("user"));
    return this.user;
  }

  getRole() {
    if(this.role) {
      return this.role;
    } else if(localStorage.getItem('role')) {
      return localStorage.getItem('role');
    } else {
      return 'basic';
    }
  }

  getAuthStatusListener() {
    return this.authStatusListener.asObservable();
  }

  getCompany() {
    return localStorage.getItem('company');
  }

  createAgent(data: any): Observable<Agents[]> {
    return this.http.post<Agents[]>(BACKEND_URL + '/user/register', data);
  }

  login(data) {
      this.http.post<{token: string, user: any, expiresIn: number, role: string}>(BACKEND_URL + '/admin/user/login', data).subscribe(response => {
        this.token = response.token;
        this.user = response.user;
        this.role = response.user.role;
        localStorage.setItem("user", JSON.stringify(response.user));
        if(response.token) {
          const expiresInDuration = response.expiresIn;
          this.setAuthTimer(expiresInDuration);
          this.isAuthenticated = true;
          this.authStatusListener.next(true);
          const now = new Date();
          const expirationDate = new Date(now.getTime() + expiresInDuration * 1000);
          this.saveAuthData(response.token, expirationDate, response.user.company, response.user.role);
          this.router.navigate(["/app/dashboard"]);
        }
      }, (res) => {
        this.notificationService.showError('User and password not correct!');
      }); 
  }

  forgotPassword(data: any): Observable<Agents[]> {
    return this.http.post<Agents[]>(BACKEND_URL + '/admin/user/forgot', data);
  }

  resetPassword(data: any, token: any): Observable<Agents[]> {
    return this.http.post<Agents[]>(BACKEND_URL + '/admin/user/reset/' + token, data);
  }

  autoAuthUser() {
    const authInformation = this.getAuthData();
    if (!authInformation) {
      return;
    }
    const now = new Date();
    const expiresIn = authInformation.expirationDate.getTime() - now.getTime();
    if (expiresIn > 0) {
      this.token = authInformation.token;
      this.isAuthenticated = true;
      this.setAuthTimer(expiresIn / 1000);
      this.authStatusListener.next(true);
    }
  }

  logout() {
    this.token = null;
    this.isAuthenticated = false;
    this.authStatusListener.next(false);
    clearTimeout(this.tokenTimer);
    this.clearAuthData();
    this.router.navigate(["/"]);
  }

  private setAuthTimer(duration: number) {
    this.tokenTimer = setTimeout(() => {
      this.logout();
    }, duration * 1000);
  }

  private saveAuthData(token: string, expirationDate: Date, company: string, role: string) {
    localStorage.setItem("token", token);
    localStorage.setItem("expiration", expirationDate.toISOString());
    localStorage.setItem("company", company);
    localStorage.setItem("role", role);
  }

  private clearAuthData() {
    localStorage.removeItem("token");
    localStorage.removeItem("expiration");
    localStorage.removeItem("company");
  }

  private getAuthData() {
    const token = localStorage.getItem("token");
    const expirationDate = localStorage.getItem("expiration");
    if (!token || !expirationDate) {
      return;
    }
    return {
      token: token,
      expirationDate: new Date(expirationDate)
    }
  }

  getCountries(): Observable<any> {
    return this.http.get('./assets/phone-code.json'); 
  }


}
