import { Component, OnInit } from '@angular/core';
import { AdminService } from '../_services/admin.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../_services/auth.service';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { NotificationService } from '../_services/notification.service';
import { PhoneValidator } from '../_util/phone.validator';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
  providers: [ConfirmationService],
})
export class LandingComponent implements OnInit {
  langs: SelectItem[];
  tableData: any;
  cols: any[];
  selectValue: string = 'yes';
  selectValue1: string = 'yes';
  selectValue2: string = 'yes';
  selectedValue: any;
  selectedValue2: any;
  selectedValue3: any;
  isLoading = true;
  isdetailLoading = true;
  addModal = false;
  detailModal = false;
  selectedDetail: any; 
  editModal = false;
  addAgentModal = false;
  createLandingForm: FormGroup;
  updateLandingForm: FormGroup;
  formSubmitted = false;
  addNewAgent: boolean = false;
  addNewLink: any;
  links: any = [];
  displayDialog: boolean = false;
  linkColumn: any[];

  constructor(private adminService: AdminService, private formBuilder: FormBuilder, private authService: AuthService, private notificationService: NotificationService, private confirmationService: ConfirmationService) { }

  ngOnInit() {
    this.cols = [
      { field: 'name', header: 'Name' },
      { field: 'url', header: 'URL' },
      { field: 'product', header: 'Product' },
      { field: 'lang', header: 'Language' },
      { field: 'images', header: 'Images' },
      { field: 'videos', header: 'Videos' },
      { field: 'published', header: 'Published' },
      { field: 'gtmId', header: 'GTM ID' },
      { field: 'links', header: 'CTA Links' }
    ];
    this.linkColumn = [
      { field: 'link', header: 'Links' }
    ]
    this.getLandings();
    this.createLandingForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      url: ['', [Validators.required]],
      lang: ['', [Validators.required]],
      images: ['', [Validators.required]],
      product: ['', [Validators.required]],
      published: ['', [Validators.required]],
      videos: ['', [Validators.required]],
      links: [''],
      gtmId: ['']
    });
    this.updateLandingForm = this.formBuilder.group({
      _id: [''],
      name: ['', [Validators.required]],
      lang: ['', [Validators.required]],
      url: ['', [Validators.required]],
      images: [this.selectedValue, [Validators.required]],
      product: ['', [Validators.required]],
      published: ['', [Validators.required]],
      videos: ['', [Validators.required]],
      links: [''],
      gtmId: ['']
    });
    this.langs = [
      {label:'Turkish', value:'TR'},
      {label:'English', value:'EN'},
      {label:'Deutsch', value:'DE'},
  ];
  }

  getLandings() {
    this.isLoading = true;
    this.adminService.getLandings().subscribe(data => {
      console.log("<<<<<<<<here>>>>>>>", data);
      this.tableData = data.reverse();
      this.isLoading = false;
    });
  }

  showDetails(event) {
    this.detailModal = true;
    this.isdetailLoading = true;
    this.selectedDetail = event; 
    this.isdetailLoading = false;
  
  }

  addTeam() {
    this.addModal = true;
  }

  editRow(data) {
    if (data.images === true) {
      this.selectedValue = 'yes';
    } else if (data.images === false) {
      this.selectedValue = 'no';
    }
    if (data.videos === true) {
      this.selectedValue2 = 'yes';
    } else if (data.videos === false) {
      this.selectedValue2 = 'no';
    }
    if (data.published === true) {
      this.selectedValue3 = 'yes';
    } else if (data.published === false) {
      this.selectedValue3 = 'no';
    }
    this.links = data.links;
    const value = {
      _id: data._id,
      name: data.name,
      lang: data.lang,
      url: data.url,
      gtmId: data.gtmId,
      product: data.product,
      images: this.selectedValue,
      videos: this.selectedValue2,
      published: this.selectedValue3
    }
    this.updateLandingForm.patchValue(value);
    this.editModal = true;
  }

  createLanding() {
    const linksArray = this.links.map((link: any) => link);
    this.createLandingForm.value.links = linksArray;
    console.log(this.createLandingForm.value);
    this.formSubmitted = true;
    if (this.createLandingForm.valid) {
      this.isLoading = true;
      this.adminService.createLanding(this.createLandingForm.value).subscribe(() => {
        this.formSubmitted = false;
        this.isLoading = false;
        this.addModal = false;
        this.getLandings();
        this.createLandingForm.reset();
      }, () => {
        this.formSubmitted = false;
        this.isLoading = false;
      });
    } else {
      this.formSubmitted = true;
      this.notificationService.showError('Please fill fields!');
    }
  }

  updateLanding() {
    const linksArray = this.links.map((link: any) => link);
    this.updateLandingForm.value.links = linksArray;
    if (this.updateLandingForm.valid) {
      this.isLoading = true;
      console.log(this.updateLandingForm.value);
      this.adminService.updateLanding(this.updateLandingForm.value).subscribe(
        () => {
          this.formSubmitted = false;
          this.isLoading = false;
          this.editModal = false;
          this.getLandings();
          this.updateLandingForm.reset();
        },
        () => {
          this.formSubmitted = false;
          this.isLoading = false;
        }
      );
    } else {
      this.formSubmitted = true;
      this.notificationService.showError('Please fill fields!');
    }
  }


  deleteRow(data) {
    if (this.tableData.length > 1) {
      console.log(data, 'data');
      this.confirmationService.confirm({
        message: 'Are you sure you want to delete the landing?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.adminService.deleteLanding(data).subscribe(() => {
            this.getLandings();
          });
        }
      });
    } else {
      this.notificationService.showError('To delete a team, please add a new team first.');
    }
  }

  openAddAgent() {
    this.addAgentModal = true;
  }
  showDialogToAdd() {
    this.displayDialog = true;
  }
  cancel() {
    this.displayDialog = false;
  }
  delete(linkData: any, index: number) {
    const dataIndex = this.links.indexOf(linkData);
    if (dataIndex !== -1) {
      this.links.splice(dataIndex, 1);
    }
  }  
  save() {
    this.links = [...this.links];
    this.links.push(
      {
        "link": this.addNewLink
      }
    );
    console.log(this.links, 'links');
    this.addNewLink = '';
    this.displayDialog = false;
  }
}
