import { Component, OnInit } from '@angular/core';
import { AdminService } from '../_services/admin.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../_services/auth.service';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { NotificationService } from '../_services/notification.service';
import { PhoneValidator } from '../_util/phone.validator';

@Component({
  selector: 'app-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.scss'],
  providers: [ConfirmationService],
})
export class TeamsComponent implements OnInit {

  tableData: any;
  cols: any[];
  isLoading = true;
  addModal = false;
  editModal = false;
  addAgentModal = false;
  createTeamForm: FormGroup;
  updateTeamForm: FormGroup;
  addAgentForm: FormGroup;
  formSubmitted = false;
  agents: SelectItem[] = [];
  pipelines: SelectItem[] = [];
  stages: SelectItem[] = [];
  companyId = this.authService.getCompany();
  addNewAgent: boolean = false;

  constructor(private adminService: AdminService, private formBuilder: FormBuilder, private authService: AuthService, private notificationService: NotificationService, private confirmationService: ConfirmationService) { }

  ngOnInit() {
    this.cols = [
      { field: 'name', header: 'Name' },
      { field: 'agents', header: 'Agents' },
      { field: 'pipeline', header: 'Pipeline' },
      { field: 'stage', header: 'Stage' },
    ];
    this.getTeams();
    this.getAgents();
    this.getPipelines();
    this.getStages();
    this.createTeamForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      agents: ['', [Validators.required]],
      pipeline: ['', [Validators.required]],
      stage: ['', [Validators.required]],
    });
    this.updateTeamForm = this.formBuilder.group({
      _id: [''],
      name: ['', [Validators.required]],
      agents: ['', [Validators.required]],
      pipeline: ['', [Validators.required]],
      stage: ['', [Validators.required]],
    });
    this.addAgentForm = this.formBuilder.group({
      agentName: ['', [Validators.required]],
      agentPhone: ['', [Validators.required, PhoneValidator.checkLimit(10000000000,9999999999999999)]],
      agentEmail: ['', [Validators.required]]
    });
  }

  getTeams() {
    this.isLoading = true;
    this.adminService.getTeams().subscribe(data => {
      this.tableData = data.reverse();
      this.isLoading = false;
    });
  }

  getAgents() {
    this.adminService.getAgents().subscribe(data => {
      const hede = [];
      data.map((line) => {
        if(!line.hidden) {
          hede.push({label: line.name, value: line._id});
        }
      });
      this.agents = hede;
      console.log(this.agents);
    });
  }

  getPipelines() {
    this.adminService.getPipelines().subscribe(data => {
      this.pipelines = data.map((line) => {
        return {label: line.name, value: line._id};
      });
    });
  }

  getStages() {
    this.adminService.getStages().subscribe(data => {
      this.stages = data.map((line) => {
        return {label: line.pipeline_name + ' - ' + line.name, value: line._id};
      });
    });
  }


  addTeam() {
    this.addModal = true;
  }

  editRow(data) {
    console.log(data);
    const agentIds = [];
    data.agents.map((agent) => {
      agentIds.push(agent._id);
    });
    const value = {
      _id: data._id,
      name: data.name,
      agents: agentIds,
      pipeline: data.pipeline !== null ? data.pipeline._id : null,
      stage: data.stage !== undefined && data.stage !== null ? data.stage._id : null
    }
    this.updateTeamForm.patchValue(value);
    this.editModal = true;
  }

  createTeam() {
    this.formSubmitted = true;
    console.log(this.createTeamForm.value)
    if(this.createTeamForm.valid) {
      this.isLoading = true;
      this.adminService.createTeam(this.createTeamForm.value).subscribe(() => {
        this.formSubmitted = false;
        this.isLoading = false;
        this.addModal = false;
        this.getTeams();
        this.createTeamForm.reset();
      }, () => {
        this.formSubmitted = false;
        this.isLoading = false;
      });
    } else {
      this.formSubmitted = true;
      this.notificationService.showError('Please fill fields!');
    }
  }

  updateTeam() {
    if(this.updateTeamForm.valid) {
      this.isLoading = true;
      this.adminService.updateTeams(this.updateTeamForm.value).subscribe(() => {
        this.formSubmitted = false;
        this.isLoading = false;
        this.editModal = false;
        this.getTeams();
        this.updateTeamForm.reset();
      }, () => {
        this.formSubmitted = false;
        this.isLoading = false;
      });
    } else {
      this.formSubmitted = true;
      this.notificationService.showError('Please fill fields!');
    }
  }

  deleteRow(data) {
    if(this.tableData.length > 1) {
      console.log(data, 'data');
      this.confirmationService.confirm({
        message: 'Are you sure you want to delete the team?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.adminService.deleteTeam(data).subscribe(() => {
            this.getTeams();
          });
        }
      });
    } else {
      this.notificationService.showError('To delete a team, please add a new team first.');
    }
  }

  openAddAgent() {
    this.addAgentModal = true;
  }

}
