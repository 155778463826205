import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/_services/auth.service';
import { NotificationService } from 'src/app/_services/notification.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  isLoading = true;
  formSubmitted = false;
  loginForm: FormGroup;

  constructor(private authService: AuthService, private formBuilder: FormBuilder, private notificationService: NotificationService) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.email]],
      password: ['', [Validators.required]]
    });
  }

  login() {
    this.formSubmitted = true;
    if(this.loginForm.valid) {
      this.isLoading = true;
      this.authService.login(this.loginForm.value);
    } else {
      this.notificationService.showError('Please fill fields!');
    }
  }
}
