import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MainComponent} from './main.component';
import {MainRoutingModule, RoutingComponents} from './main-routing.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule} from '@angular/common/http';
import {LoadingComponent} from '../common/loading/loading.component';

import {TableModule} from 'primeng/table';
import {DialogModule} from 'primeng/dialog';
import {FileUploadModule} from 'primeng/fileupload';
import {ChartModule} from 'primeng/chart';
import {DropdownModule} from 'primeng/dropdown';
import {RadioButtonModule} from 'primeng/radiobutton';
import {CalendarModule} from 'primeng/calendar';
import {ToastModule} from 'primeng/toast';
import {CheckboxModule} from 'primeng/checkbox';
import {MultiSelectModule} from 'primeng/primeng';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import { TopNavbarComponent } from '../common/top-navbar/top-navbar.component';
import { NavbarComponent } from '../common/navbar/navbar.component';
import { FooterComponent } from '../common/footer/footer.component';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import {PaginatorModule} from 'primeng/paginator';
import {ToolbarModule} from 'primeng/toolbar';

@NgModule({
  imports: [
    CommonModule,
    MainRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TableModule,
    DialogModule,
    FileUploadModule,
    ChartModule,
    RadioButtonModule,
    DropdownModule,
    CalendarModule,
    ToastModule,
    CheckboxModule,
    MultiSelectModule,
    ConfirmDialogModule,
    NgxJsonViewerModule,
    PaginatorModule,
    ToolbarModule
  ],
  declarations: [MainComponent, RoutingComponents, LoadingComponent, TopNavbarComponent, NavbarComponent, FooterComponent],
  exports: [MainComponent]
})
export class MainModule {
}
