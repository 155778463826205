import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MainComponent} from './main.component';

import { DashboardComponent } from '../dashboard/dashboard.component';
import { LandingComponent } from '../landing/landing.component';
import { LogComponent } from '../log/log.component';
import { OfferComponent } from '../offer/offer.component';
import { LeadComponent } from '../lead/lead.component';
import { AgentsComponent } from '../agents/agents.component';
import { AuthGuard } from '../_guards/auth-guard';
import { TeamsComponent } from '../teams/teams.component';
import { UsersComponent } from '../users/users.component';
import { AgentComponent } from '../agent/agent.component';

export const RoutingComponents = [
    DashboardComponent,
    AgentsComponent,
    TeamsComponent,
    UsersComponent,
    AgentComponent,
    LandingComponent,
    LogComponent,
    LeadComponent,
    OfferComponent
];

const routes: Routes = [
    {
        path: 'app', component: MainComponent,
        canActivate: [AuthGuard],
        children: [
            {path: 'dashboard', component: DashboardComponent},
            {path: 'agents', component: AgentsComponent},
            {path: 'teams', component: TeamsComponent},
            {path: 'users', component: UsersComponent},
            {path: 'agent', component: AgentComponent},
            {path: 'landing', component: LandingComponent},
            {path: 'logs', component: LogComponent},
            {path: 'leads', component: LeadComponent},
            {path: 'offers', component: OfferComponent}
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [AuthGuard]
})

export class MainRoutingModule {
}