import { Component, OnInit } from '@angular/core';
import { AdminService } from '../_services/admin.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../_services/auth.service';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { NotificationService } from '../_services/notification.service';
import { PhoneValidator } from '../_util/phone.validator';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  providers: [ConfirmationService],
})
export class UsersComponent implements OnInit {

  tableData: any;
  cols: any[];
  roles: any[];
  isLoading = true;
  addModal = false;
  editModal = false;
  addAgentModal = false;
  createUserForm: FormGroup;
  updateUserForm: FormGroup;
  formSubmitted = false;
  agents: SelectItem[] = [];
  pipelines: SelectItem[] = [];
  stages: SelectItem[] = [];
  companyId = this.authService.getCompany();
  addNewAgent: boolean = false;

  constructor(private adminService: AdminService, private formBuilder: FormBuilder, private authService: AuthService, private notificationService: NotificationService, private confirmationService: ConfirmationService) { }

  ngOnInit() {
    this.cols = [
      { field: 'name', header: 'Name' },
      { field: 'email', header: 'Email' },
      { field: 'role', header: 'Role' }
    ];
    this.roles = [
      {label: "Admin", value: "admin"},
      {label: "Agent", value: "basic"}
    ];
    this.getUsers();
    this.getAgents();
    this.createUserForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      role: ['', [Validators.required]],
      password: ['', [Validators.required]],
      rePassword: ['', [Validators.required]],
      agent: ['' ]
    });
    this.updateUserForm = this.formBuilder.group({
      _id: [''],
      name: ['', [Validators.required]],
      email: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      role: ['', [Validators.required]],
      password: ['', [Validators.required]],
      rePassword: ['', [Validators.required]],
      agent: ['' ]
    });
  }

  getUsers() {
    this.isLoading = true;
    this.tableData = [];
    this.adminService.getUsers().subscribe(data => {
      this.tableData = data.reverse();
      this.isLoading = false;
    });
  }

  getAgents() {
    this.adminService.getAgents().subscribe(data => {
      const hede = [];
      data.map((line) => {
        if(!line.hidden) {
          hede.push({label: line.name, value: line._id});
        }
      });
      this.agents = hede;
    });
  }

  addUser() {
    this.addModal = true;
  }

  createUser() {
    this.formSubmitted = true;
    if(this.createUserForm.valid) {
      this.isLoading = true;
      this.adminService.createUser(this.createUserForm.value).subscribe(() => {
        this.formSubmitted = false;
        this.isLoading = false;
        this.addModal = false;
        this.getUsers();
        this.createUserForm.reset();
      }, () => {
        this.formSubmitted = false;
        this.isLoading = false;
      });
    } else {
      this.formSubmitted = true;
      this.notificationService.showError('Please fill fields!');
    }
  } 

}
