import { Component, OnInit } from "@angular/core";
import { AdminService } from "../_services/admin.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AuthService } from "../_services/auth.service";
import { PhoneValidator } from "../_util/phone.validator";
import { NotificationService } from "../_services/notification.service";
import * as XLSX from "xlsx";
import { ConfirmationService, SelectItem } from "primeng/api";

@Component({
  selector: "app-agent",
  templateUrl: "./agent.component.html",
  styleUrls: ["./agent.component.scss"],
  providers: [ConfirmationService],
})
export class AgentComponent implements OnInit {
  tableData: any[] = [];
  cols: any[];
  isLoading = true;
  addModal = false;
  editModal = false;
  updateAgentForm: FormGroup;
  formSubmitted = false;
  companyId = this.authService.getCompany();
  preDays: any = {
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  };
  days: SelectItem[] = [
    { label: "Monday", value: { name: "monday" } },
    { label: "Tuesday", value: { name: "tuesday" } },
    { label: "Wednesday", value: { name: "wednesday" } },
    { label: "Thursday", value: { name: "thursday" } },
    { label: "Friday", value: { name: "friday" } },
    { label: "Saturday", value: { name: "saturday" } },
    { label: "Sunday", value: { name: "sunday" } },
  ];
  workingDaysData: any;
  workingDaysCols: any[];
  pipelines: SelectItem[] = [];
  user: any;

  constructor(
    private adminService: AdminService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private notificationService: NotificationService,
    private confirmationService: ConfirmationService
  ) { }

  ngOnInit() {
    this.workingDaysCols = [
      { field: "active", header: "Active" },
      { field: "dayName", header: "Day" },
      // { field: "startTime", header: "Start Time" },
      // { field: "endTime", header: "End Time" },
    ];
    this.getAgent();
    this.user = this.authService.getUser();
  }

  getAgent() {
    this.isLoading = true;
    this.adminService.getAgentByID(this.authService.getUser().agent).subscribe((data) => {
      this.editRow(data);
      this.tableData.reverse();
      this.isLoading = false;
    });
  }

  editRow(data) {
    let days = [];
    if (data.days.length > 0) {
      data.days.map((day) => {
        days.push({
          active: day.active,
          dayName: day.dayName,
        });
      });
    } else {
      const preDays = [
        {
          "active": false,
          "dayName": "Monday",
        },
        {
          "active": false,
          "dayName": "Tuesday",
        },
        {
          "active": false,
          "dayName": "Wednesday",
        },
        {
          "active": false,
          "dayName": "Thursday",
        },
        {
          "active": false,
          "dayName": "Friday",
        },
        {
          "active": false,
          "dayName": "Saturday",
        },
        {
          "active": false,
          "dayName": "Sunday",
        }
      ];
      preDays.map((day) => {
        days.push({
          active: day.active,
          dayName: day.dayName,
        });
      });
    }
    this.workingDaysData = days;
  }

  updateAgent() {
    this.formSubmitted = true;
    const data = {
      _id: this.user.agent,
      days: this.workingDaysData
    };
    this.isLoading = true;
    this.adminService.updateAgent(data, this.user.agent).subscribe(
      () => {
        this.formSubmitted = false;
        this.isLoading = false;
        this.editModal = false;
        this.getAgent();
        this.updateAgentForm.reset();
        this.workingDaysData = [];
      },
      (res) => {
        window.alert(res.error.error);
        this.getAgent();
        this.formSubmitted = false;
        this.isLoading = false;
      }
    );
  }
}
